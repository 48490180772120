<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="6">
            <b-button
              variant="outline-primary"
              @click="selectAllRows"
            >
              Select all
            </b-button>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-if="$can('delete', 'instructor')"
            cols="6"
            class="d-flex align-items-end justify-content-end mb-1"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="DeleteRows"
            >
              Delete Selected Instructors
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="4"
            md="4"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="8"
            md="8"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />

          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="allItem"
        select-mode="multi"
        selectable
        :sort-by.sync="sort"
        show-empty
        empty-text="Loading.."

        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a>
            <router-link
              :to="`/instructors/${data.item.id}`"
              style="margin: 0px 12px"
            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
          <a
            v-if="$can('delete', 'instructor')"
            role="button"
            @click="DeleteInstructor(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,

  BPagination,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,

    BPagination,

    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const totalItems = ref(0)
    const selectAllRows = () => {
      refCourseListTable.value.selectAllRows()
    }
    const sort = ref('')
    const SortArray = []

    const currentPage = ref(1)
    const searchQuery = ref('')
    const allItem = ref([])

    const selectedRow = ref([])
    const onRowSelected = items => {
      selectedRow.value = items
    }
    const GetAllInstructor = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }
      store
        .dispatch('instructor/getAllinstructor', {
          'filter[search]': searchQuery.value,
          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          // coursesList.value=response.data.data
          allItem.value = response.data.data
          totalItems.value = response.data.meta.total

          // callback(data)
        })
    }
    const DeleteRows = () => {
      const data = []
      selectedRow.value.forEach(el => {
        data.push(el.id)
      })
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store
            .dispatch('instructor/DeleteSelected', data)
            .then(response => {
              GetAllInstructor()
              Vue.swal({
                title: 'instructor Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const refetchData = () => {
      refCourseListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sort], () => {
      GetAllInstructor()
    })

    GetAllInstructor()
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      }
    })
    // GetAllInstructor()
    const DeleteInstructor = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('instructor/DeleteInstructor', id).then(response => {
            GetAllInstructor()
            Vue.swal({
              title: 'instructor Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', lanel: 'ID', sortable: true },
      { key: 'name', label: ' Name', sortable: true },
      { label: 'Teaching Field', key: 'field', sortable: true },

      { key: 'actions' },
    ]

    return {
      tableColumns,
      coursesList,
      DeleteInstructor,
      meta,
      perPageOptions,
      totalItems,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllInstructor,
      searchQuery,
      allItem,
      onRowSelected,
      selectedRow,
      DeleteRows,
      selectAllRows,
      SortArray,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
